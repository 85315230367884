import type {
  AuthOtpResponse,
  AuthResponse,
  AuthTokenResponsePassword
} from '@supabase/auth-js'
import type { Database, Enums } from '~~/types/supabase'

export default function useAuth() {
  const supabase = useSupabaseClient<Database>()

  // Initialize the state reference immediately
  const currentUserRole = useState<Enums<'app_role'> | null>(
    'rn_user_role',
    () => null
  )

  async function logout() {
    await supabase.auth.signOut()
    await navigateTo('/login')
  }

  async function sendOtp(
    email: string
  ): Promise<Omit<AuthOtpResponse, 'data'>> {
    const { error } = await supabase.auth.signInWithOtp({
      email,
      options: { shouldCreateUser: false }
    })

    return { error }
  }

  async function signInWithPassword(
    email: string,
    password: string
  ): Promise<Omit<AuthTokenResponsePassword, 'data'>> {
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password
    })

    return { error }
  }

  async function verifyOtp(
    email: string,
    token: string
  ): Promise<Omit<AuthResponse, 'data'>> {
    const { error } = await supabase.auth.verifyOtp({
      email,
      token,
      type: 'email'
    })

    return { error }
  }

  return { currentUserRole, logout, sendOtp, signInWithPassword, verifyOtp }
}
